import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import BodyView from '@/views/BodyView.vue'
import ChallengeView from '@/views/ChallengeView.vue'
import NewsView from '@/views/NewsView.vue'
import FouleesView from '@/views/FouleesView.vue'
import AlbumsView from '@/views/AlbumsView.vue'
import EventsView from '@/views/EventsView.vue'
import GaleriesView from '@/views/GaleriesView.vue'
import CoursesView from '@/views/CoursesView.vue'
import InscritsView from '@/views/InscritsView.vue'
import EntrainementsView from '@/views/EntrainementsView.vue'
import NosEntraineursView from '@/views/NosEntraineursView.vue'
import TarifsView from '@/views/TarifsView.vue'
import InscriptionsView from '@/views/InscriptionsView.vue'
import BoutiqueView from '@/views/BoutiqueView.vue'
import ComiteView from '@/views/ComiteView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: BodyView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: 'comite',
        name: 'comite',
        component: ComiteView
      },
      {
        path: 'entrainement',
        name: 'entrainement',
        component: EntrainementsView
      },
      {
        path: 'entraineur',
        name: 'entraineur',
        component: NosEntraineursView
      },
      {
        path: 'tarif',
        name: 'tarif',
        component: TarifsView
      },
      {
        path: 'inscription',
        name: 'inscription',
        component: InscriptionsView
      },
      {
        path: 'boutique',
        name: 'boutique',
        component: BoutiqueView
      },
      {
        path: 'challenge',
        name: 'challenge',
        component: ChallengeView
      },
      {
        path: '/course/:id',
        name: 'course',
        component: CoursesView
      },
      {
        path: 'course/:id/inscrits',
        name: 'inscrit',
        component: InscritsView
      },
      {
        path: 'news',
        name: 'news',
        component: NewsView
      },
      {
        path: 'galeries',
        name: 'galeries',
        component: GaleriesView
      },
      {
        path: 'albums/:id',
        name: 'albums',
        component: AlbumsView
      },
      {
        path: 'events',
        name: 'events',
        component: EventsView
      },
      {
        path: 'foulees',
        name: 'foulees',
        component: FouleesView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
