<script setup lang="ts">

import AlbumsService from '../services/albums.service.js'
import { ref } from 'vue'
const albums = ref(null)
AlbumsService.getAllAlbums().then(
  (response) => {
    albums.value = response.data
  },
  (error: any) => {
    albums.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const file = ref(null)
const el = document.getElementById('albumsPhotos')
if (el) {
  el.addEventListener('fileUploadSuccess', function (e) {
    console.log(e.detail.files)
  })
}
</script>
<template>
    <div class="text_titre">
        <h1>Galerie photos du club</h1>
    </div>
    <section class="galerie">
        <div class="album" v-for="album in albums" v-bind:key="album.id">
          <router-link :to="'/albums/' + album.id">
          <img class="img_album" :src="album.Image" alt="" />
          <h1>{{album.Nom}}</h1>
            </router-link>
        </div>
    </section>
</template>

<style scoped>
.text_titre {
  width: 30%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  border: 5px solid #fdce08;
}
.galerie {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.album {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 2%;
}
.album > h1 {
  font-size: 150%;
  color: #0872b1;
}
.img_album{
  width: 100%;
  height: 28vh;
  object-fit: cover;
}
.album a{
  text-decoration: none;
  color: #0872b1;
}

@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .album {
    width: 100%;
  }
}
</style>
