import axios from 'axios'

const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'

class EventsService {
  getEvents () {
    return axios
      .get(API_URL + 'events')
  }

  getEventsNext () {
    return axios
      .get(API_URL + 'eventsNext')
  }
}

export default new EventsService()
