<script setup lang="ts">

import { ref } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import CoursesService from '../services/courses.service.js'
import CoureursService from '../services/coureur.service.js'
import router from '@/router'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const NomPrenom = ref('')
const NumLic = ref('')
const Parcours = ref('')
const route = useRoute()

const course = ref(null)
CoursesService.getCourse(route.params.id).then(
  (response) => {
    course.value = response.data
  },
  (error: any) => {
    course.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const coureurs = ref(null)
CoureursService.getCoureurs().then(
  (response) => {
    coureurs.value = response.data
  },
  (error: any) => {
    coureurs.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getImageUrl (name: string) {
  return '/Flyers/' + name
}

function handleSubmit (e: any) {
  const coureur = NomPrenom.value.split(' ')
  let Nom
  let Prenom
  if (coureur.length === 3) {
    Nom = coureur[0] + ' ' + coureur[1]
    Prenom = coureur[2]
  } else {
    Nom = coureur[0]
    Prenom = coureur[1]
  }

  const body = {
    Parcours: Parcours.value,
    Nom: Nom,
    Prenom: Prenom,
    NumLic: NumLic.value
  }

  CoursesService.postInscription(route.params.id, body).then(response => {
  // console.log('File uploaded successfully:', response.data);
    alert('Inscription envoyé')
    router.push('/challenge')
  }).catch(error => {
    console.error('Error uploading file:', error)
  })
}
</script>
<template>
    <div class="text_titre" v-if="course">
        <h1>{{ course.Nomcourse_courses }}</h1>
    </div>

    <h3>Inscriptions aux courses du challenge pour les licenciés du club uniquement</h3>

    <section class="containerForm"  v-if="course && coureurs">
        <div class="flyer">
            <img :src="course.Flyer" alt="">
            <!--<a href="">Télecharger le flyers (horaires & règlements)</a>-->
        </div>
        <div class="form">
            <div class="date">
                <h2>{{ moment(course.Datecourse_courses).locale('fr').format('dddd DD MMMM YYYY') }}</h2>
                <h4>Inscription jusqu'au {{ moment(course.Datecourse_courses).locale('fr').subtract(10, 'd').format('dddd DD MMMM YYYY') }}</h4>
            </div>
            <form ref="myForm" @submit.prevent="handleSubmit($event)" class="formulaire">
                <div class="listeInscrits">
                    <h2>Formulaire d'inscription</h2>
                    <RouterLink :to="{name : 'inscrit', params: {id: course.id}}">Liste des inscrits</RouterLink>
                </div>

                <label for="parcour">Course</label>
                <select name="parcour" id="parcour" v-model="Parcours" required>
                    <option value="">--Choisissez une course--</option>
                    <option v-for="data in course.parcourirs" v-bind:key="data.Id" :value="data.Numero">{{ data.Heure }} - {{ data.Nom }} - {{ data.Distance }}km</option>
                </select>

                <label for="NomPrenom">Nom et Prénom</label>
                <input type="text" id="NomPrenom" list="licencie" name="NomPrenom" class="input" autocomplete="off" v-model="NomPrenom" required/>

                <datalist id="licencie">
                    <option  v-for="data in coureurs" v-bind:key="data.Id" :value="data.NumLic_Coureur">{{ data.Nom }} {{ data.Prenom }}</option>
                </datalist>

                <label for="NumLic">Numéro de licence</label>
                <input type="text" id="NumLic" name="NumLic" class="input" v-model="NumLic" required />

                <button type="submit" class="submit">Envoyer</button>
            </form>
        </div>

    </section>

</template>

<style scoped>
    .text_titre {
        width: 40%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: 5%;
        text-align: center;
        border: 5px solid #fdce08;
    }
    h3{
        text-align: center;
        color: red;
        margin-top: -3%;
        margin-bottom: 2%;
    }
    .containerForm{
        width: 80%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .flyer{
        width: 30%;
        background-color: #fff;
        padding: 1%;
        border-radius: 10px;
    }
    .flyer img{
        width: 100%;
        object-fit: contain;
        margin-bottom: 4%;
    }
    .flyer a{
        display: flex;
        justify-content: center;
        color: #0872b1;
        text-decoration: none;
    }
    .form{
        width: 66%;
    }
    .date{
        width: 100%;
        background-color: #fff;
        padding: 2%;
        border-radius: 10px;
        text-transform: capitalize;
    }
    .formulaire{
        width: 100%;
        background-color: #fff;
        padding: 2%;
        border-radius: 10px;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .listeInscrits{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .listeInscrits a{
        color: #0872b1;
        font-size: 120%;
    }
    .formulaire label{
        font-size: 120%;
        margin-bottom: 1%;
        align-self: flex-start;
    }
    .formulaire select{
        width: 100%;
        font-size: 120%;
        padding: 1%;
        margin-bottom: 2%;
        border-radius: 5px;
    }
    .input{
        width: 98%;
        font-size: 120%;
        padding: 1%;
        margin-bottom: 2%;
        border-radius: 5px;
        border: 1px solid #767676;
    }
    .submit{
        width: 100%;
        font-size: 120%;
        padding: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
        border-radius: 5px;
        background-color: #0872b1;
        border: none;
        color: #fff;
        cursor: pointer;
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            width: 80%;
            margin: auto;
            margin-top: 8%;
            margin-bottom: 6%;
            text-align: center;
            border: 5px solid #fdce08;
        }
        .text_titre h1{
            font-size: 175%;
        }
        h3{
            width: 80%;
            margin: auto;
            margin-bottom: 5%;
        }
        .flyer{
            width: 100%;
            margin-bottom: 4%;
        }
        .form{
            width: 96%;
            font-size: 90%;
        }
        .date{
            margin-bottom: 2%;
        }
        .listeInscrits{
            flex-direction: column;
            margin-bottom: 4%;
        }
        .formulaire label{
            font-size: 150%;
        }
        .formulaire select{
            font-size: 150%;
            margin-bottom: 6%
        }
        .input{
            margin-bottom: 6%;
            font-size: 150%;
        }
        .submit{
            padding: 5%;
            font-size: 150%;
        }
    }
    @media screen and (max-width : 960px) {
        .containerForm{
            width: 90%;
        }
    }
</style>
