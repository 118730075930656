<script setup lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

</script>

<template>
    <div class="text_titre">
        <h1>Les membres du bureau</h1>
    </div>
    <section class="containerEntraineurs">
        <div class="entraineur">
            <img src="@/assets/eric.jpg" class="imgEntraineur" alt="">
            <h1>Eric BEYS</h1>
            <h3>Président</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/laurent.jpg" class="imgEntraineur" alt="">
            <h1>Laurent COUSTER</h1>
            <h3>Vice-Présient</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/fred.jpg" class="imgEntraineur" alt="">
            <h1>Fred LECOUSTRE</h1>
            <h3>Secrétaire</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/libert.jpg" class="imgEntraineur" alt="">
            <h1>Frédéric LIBERT</h1>
            <h3>Trésorier</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/sghaier.jpg" class="imgEntraineur" alt="">
            <h1>Sghaier WADOUX</h1>
            <h3>Vice-Présient</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/lydia.jpg" class="imgEntraineur" alt="">
            <h1>Lydia DEMEYER</h1>
            <h3>Secrétaire adjointe</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/olivier.jpeg" class="imgEntraineur" alt="">
            <h1>Olivier HECQUET</h1>
            <h3>Membre</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/christophe.jpg" class="imgEntraineur" alt="">
            <h1>Christophe DHONDT</h1>
            <h3>Membre</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/benoit.jpg" class="imgEntraineur" alt="">
            <h1>Benoit PEUTEVYNCK</h1>
            <h3>Membre</h3>
        </div>
        <div class="entraineur">
            <img src="@/assets/elodie.jpg" class="imgEntraineur" alt="">
            <h1>Elodie DHONDT</h1>
            <h3>Membre</h3>
        </div>
    </section>
</template>

<style scoped>
.text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
}
.containerEntraineurs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
}
.entraineur{
    width: 24%;
    text-align: center;
    margin-bottom: 4%
}
.entraineur h1{
    color: #0872b1;
    font-size: 180%;
    margin-top: 2%;
    margin-bottom: 0;
}
.entraineur h3{
   color: #979797;
   margin-top: 1%;
}
.imgEntraineur{
    width: 70%;
    border-radius: 150px;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .imgEntraineur{
    width: 60%;
  }
  .entraineur{
    width: 100%;
  }
}
</style>
