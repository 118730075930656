<script setup lang="ts">

import CoursesService from '../services/courses.service.js'
import moment from 'moment'
import 'moment/dist/locale/fr'
import router from '@/router'
import { ref } from 'vue'
moment.locale('fr')

const courses = ref()
CoursesService.getAllCourses().then(
  (response: { data: any }) => {
    courses.value = response.data
  },
  (error: any) => {
    courses.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const courseNext = ref()
CoursesService.getNextCourse().then(
  (response: { data: any }) => {
    courseNext.value = response.data
  },
  (error: any) => {
    courseNext.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getImageUrl (name: any) {
  return '/Flyers/' + name
}
function getImageUrlBack (name: any) {
  return '/Background/' + name
}
</script>

<template>
    <div class="text_titre">
        <h1>Les courses du challenge</h1>
    </div>

    <h3>Inscriptions aux courses du challenge pour les licenciés du club uniquement</h3>

    <section class="prochaineCourses" v-if="courseNext" v-bind:style="{ 'background-image': 'url(' + courseNext.Background + ')', 'background-repeat' : 'no-repeat', 'background-size' : 'cover'}">
        <img class="imageFlyers" :src="courseNext.Flyer"  alt="flyer" />
        <div class="right_prochaineCourses">
            <h2>{{ moment(courseNext.Datecourse_courses).locale('fr').format('dddd DD MMMM YYYY') }}</h2>
            <h2>{{ courseNext.Nomcourse_courses }}</h2>
            <h4>1er course du challenge Dunes de Flandre 2024</h4>
            <h4>Courses :</h4>
            <ul v-if="courseNext">
                <li v-for="data in courseNext.parcourirs" v-bind:key="data.id">{{ data.Heure }} - {{ data.Nom }} - {{ data.Distance }}km</li>
            </ul>
            <h4>Inscriptions ouvertes juqu'au {{ moment(courseNext.Datecourse_courses).subtract(10, 'd').locale('fr').format('ddd DD MMM YYYY') }}</h4>
            <button><RouterLink :to="{ name: 'course', params: {  id: courseNext.id} }">S'inscrire</RouterLink></button>
            <button><RouterLink :to="{ name: 'inscrit', params: {  id: courseNext.id} }">Liste des inscrits</RouterLink></button>
        </div>
    </section>

    <div class="courses" v-if="courses">
        <div v-for="data in courses" class="course" v-bind:key="data.id">
            <RouterLink :to="{ name: 'course', params: {  id: data.id} }">
                <img class="imageFlyers" :src="getImageUrl(data.Flyer)" alt="" />
                <h2>{{data.Nomcourse_courses}}</h2>
            </RouterLink>
        </div>
    </div>

</template>

<style scoped>
    .text_titre {
        width: 40%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: 5%;
        text-align: center;
        border: 5px solid #fdce08;
    }
    h3{
        text-align: center;
        color: red;
        margin-top: -3%;
        margin-bottom: 2%;
    }
    .prochaineCourses{
        width: 80%;
        height: 800px;
        margin: auto;
        padding: 4%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: #fff;
        border-radius: 10px;
        text-shadow: 2px 2px 2px #000;
    }
    .right_prochaineCourses{
        background-color: #1a12128c;
        padding: 1%;
    }
    .prochaineCourses h2{
        font-size: 250%;
        text-transform: capitalize;
    }
    .prochaineCourses h4{
        font-size: 175%;
        margin-bottom: 0;
    }
    .prochaineCourses ul li{
        font-size: 150%;
    }
    .prochaineCourses button{
        background-color: transparent;
        border: 4px solid #fff;
        font-size: 220%;
        padding: 2%;
        width: 70%;
        justify-content: center;
        margin-top: 4%;
        margin-left: 18%;
    }
    .prochaineCourses a{
        text-decoration: none;
        text-shadow: 2px 2px 2px #000;
        color: #fff;
    }
    .prochaineCourses img{
        width: 45%;
        object-fit: contain;
    }
    .prochaineCourses button:hover{
        background-color: #ffffff69;
        cursor: pointer;
    }
    .courses{
        width: 80%;
        height: 600px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 2%;
    }
    .course{
        width: 20%;
        margin-bottom: 2%;
        border-radius: 10px;
    }
    .course h2{
        color: #0872b1;
        font-family: Rockwell;
    }
    .course img{
        width: 100%;
        object-fit: contain;
    }
    @media (min-width: 1024px) and (max-width: 1850px) {
        .prochaineCourses{
            font-size: 80%;
        }
        .prochaineCourses img{
            width: 50%;
        }
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            width: 80%;
            margin: auto;
            margin-top: 8%;
            margin-bottom: 6%;
            text-align: center;
            border: 5px solid #fdce08;
        }
        .text_titre h1{
            font-size: 175%;
        }
        h3{
            width: 80%;
            margin: auto;
            margin-bottom: 5%;
        }
        .prochaineCourses{
            display: flex;
            flex-direction: column;
            justify-content:flex-start;
            height: auto;
            font-size: 70%;
        }
        .prochaineCourses img{
            width: 100%;
            object-fit: contain;
        }
        .prochaineCourses button{
            width: 100%;
            margin-left: 0;
        }
        .courses{
            margin-top: 5%;
            height: auto;
        }
        .course{
            width: 100%;
        }
        ul{

            padding-left: 6%;
        }
    }
</style>
