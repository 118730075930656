<script setup lang="ts">
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

// eslint-disable-next-line no-undef
const events = defineProps({
  id: Number,
  Nom: String,
  Img: String,
  Date: String,
  Type: String,
  idCourses: String,
  Lien: String,
  Lieu: String,
  Details: String,
  Eveil: String,
  Poussins: String,
  Benjamins: String,
  Minimes: String,
  Cadets: String,
  Fond: String,
  Running: String,
  Marche: String,
  Toutes: String,
  Autres: String
})

</script>

<template>
    <div class="itemEvent">
        <div class="event_date">
            <h3>{{ moment(events.Date).format('ddd') }}</h3>
            <h2>{{ moment(events.Date).format('DD') }}</h2>
            <h3>{{ moment(events.Date).format('MMM') }}</h3>
            <hr>
        </div>
        <div class="event_desc">
            <h2>{{events.Nom}}</h2>
            <div class="event_categ_list">
            <div class="event_categ_item toutes" v-if="events.Toutes == 'Oui'">
                <h4>Toutes catégories</h4>
            </div>
            <div class="event_categ_item eveil" v-if="events.Eveil == 'Oui'">
                <h4>Eveil Athlé</h4>
            </div>
            <div class="event_categ_item poussins" v-if="events.Poussins == 'Oui'">
                <h4>Poussins</h4>
            </div>
            <div class="event_categ_item benjamins" v-if="events.Benjamins == 'Oui'">
                <h4>Benjamins</h4>
            </div>
            <div class="event_categ_item minimes" v-if="events.Minimes == 'Oui'">
                <h4>Minimes</h4>
            </div>
            <div class="event_categ_item cadets" v-if="events.Cadets == 'Oui'">
                <h4>Sprinteur</h4>
            </div>
            <div class="event_categ_item fond" v-if="events.Fond == 'Oui'">
                <h4>Demi-Fond</h4>
            </div>
            <div class="event_categ_item running" v-if="events.Running == 'Oui'">
                <h4>Running</h4>
            </div>
            <div class="event_categ_item autres" v-if="events.Autres == 'Oui'">
                <h4>Autres</h4>
            </div>
            </div>
            <RouterLink class="buttonInscription" :to="{ name: 'course', params: {  id: events.idCourses} }" v-if="events.Type == 'Course'">
              <p>S'incrire (Licencié du club)</p>
            </RouterLink>
            <a :href="events.Lien" target="_blank" class="buttonInscription" v-if="events.Lien != ''">
              <p>Lien d'inscription</p>
            </a>
            <div class="event_lieu" v-if="events.Type == 'Piste'">
            <h4>{{events.Lieu}}</h4>
            </div>
        </div>
        <div class="event_img">
            <img :src="events.Img" alt="">
        </div>
    </div>
</template>

<style scoped>
.itemEvent{
  width: 100%;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 2%;
}
.event_date{
    width: 8%;
    height: 100px;
    margin: 2%;
    padding: 1%;
    text-align: center;
    background-color: #0872b1;
    color: #fff;
    border-radius: 5px;
}
.event_date hr{
  color: #fdce08;
  border: 2px solid #fdce08;
  margin-top: -8%
}
.event_date h2{
  margin-top: 0%;
  margin-bottom: 0;
}
.event_date h3:nth-child(1){
  margin-bottom: 2%;
  margin-top: 10%;
  text-transform: capitalize;
}
.event_date h3{
  margin-top: 2%;
}
.event_desc{
  margin: 1%;
  width: 60%;
}
.event_desc h2{
  margin-bottom: 2%;
}
.event_categ_list{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 4%;
}
.event_categ_item{
  min-width: 20%;
  margin-left: 1%;
  border-radius: 5px;
  text-align: center;
}
.event_categ_item h4{
  margin: 4%;
}
.buttonInscription{
  display: flex;
  justify-content: center;
  background-color: #d9d9d9;
  color: #0872b1;
  text-decoration: none;
  font-weight: bold;
  width: 40%;
  margin-top: 2%;
  margin-left: 1%;
  border-radius: 5px;
}
.event_lieu{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -4%;
}
.event_lieu h4{
  margin-left: 2%;
  color: #a4a4a4;
}
.event_img{
  width: 30%;
}
.event_img img{
  width: 100%;
  height: 200px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  object-fit: cover;
}
.autres{
  background-color: #bdbdbd;
}
.toutes{
  background-color: #bdbdbd;
  width: 30%;
}
@media (min-width: 1024px) and (max-width: 1300px){
  .autres{
    background-color: #bdbdbd;
  }
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .evenement{
    width: 86%;
  }
  .itemEvent{
    height: 220px;
    margin-bottom: 4%;
  }
  .ItemCateg{
    width: 46%;
    align-self: center;
  }
  .event_date{
    width: 15%;
  }
  .event_desc{
    width: 70%;
  }
  .event_desc h2{
    font-size: 120%;
  }
  .event_categ_item{
    width: 48%;
    font-size: 80%;
    margin-bottom: 2%;
  }
  .event_img{
    width: 20%;
  }
  .event_img img{
    height: 220px;
  }
  @media screen and (max-width : 960px) {
    .event_img{
      display: none;
    }
    .itemEvent{
      padding: 2%;
      height: 140px;
    }
    .event_categ_list{
      margin-bottom: 0%;
    }
    .toutes{
      width: 60%;
    }
    .buttonInscription {
      width: 90%;
      font-size: 90%;
      margin-top: 4%;
    }
    .buttonInscription p{
      margin: 5%;
    }
  }
}
</style>
