<script setup lang="ts">

import CoursesService from '../services/courses.service.js'
import { ref } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const route = useRoute()

const course = ref(null)
CoursesService.getCourse(route.params.id).then(
  (response) => {
    course.value = response.data
  },
  (error: any) => {
    course.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const inscrire = ref(null)
CoursesService.getInscrit(route.params.id).then(
  (response) => {
    inscrire.value = response.data
  },
  (error: any) => {
    inscrire.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getImageUrl (name: string) {
  return '/Flyers/' + name
}
</script>
<template>
    <div class="text_titre" v-if="course">
        <h1>{{ course.Nomcourse_courses }}</h1>
    </div>

    <h3>Inscriptions aux courses du challenge pour les licenciés du club uniquement</h3>

    <section class="containerForm"  v-if="course">
        <div class="flyer">
            <img :src="getImageUrl(course.Flyer)" alt="">
            <a href="">Télecharger le flyers (horaires & règlements)</a>
        </div>
        <div class="form">
            <div class="date">
                <h2>{{ moment(course.Datecourse_courses).locale('fr').format('dddd DD MMMM YYYY') }}</h2>
                <h4>course du challenge Dunes de Flandres 2024</h4>
            </div>
            <div ref="myForm" class="formulaire">
                <div class="listeInscrits">
                    <h2>Liste des inscrits</h2>
                </div>

                <h4 v-if="!inscrire.length">Pas encore d'inscrits</h4>
                <div class="containerTable">
                    <table class="styled-table"  v-if="inscrire.length">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Numéro de course</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in inscrire" v-bind:key="data.Id">
                                <td>{{ data.Nom_Coureur }}</td>
                                <td>{{ data.Prenom_Coureur }}</td>
                                <td>{{ data.Numerocourse_parcourir }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

</template>

<style scoped>
    .text_titre {
        width: 40%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: 5%;
        text-align: center;
        border: 5px solid #fdce08;
    }
    h3{
        text-align: center;
        color: red;
        margin-top: -3%;
        margin-bottom: 2%;
    }
    .containerForm{
        width: 80%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .flyer{
        width: 30%;
        background-color: #fff;
        padding: 1%;
        border-radius: 10px;
    }
    .flyer img{
        width: 100%;
        object-fit: contain;
        margin-bottom: 4%;
    }
    .flyer a{
        display: flex;
        justify-content: center;
        color: #0872b1;
        text-decoration: none;
    }
    .form{
        width: 66%;
    }
    .date{
        width: 100%;
        background-color: #fff;
        padding: 2%;
        border-radius: 10px;
        text-transform: capitalize;
    }
    .formulaire{
        width: 100%;
        background-color: #fff;
        padding: 2%;
        border-radius: 10px;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .listeInscrits{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .containerTable{
        width: 100%;
        height: 400px;
        overflow-y: scroll;
    }
    .styled-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.9em;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
    .styled-table thead tr {
        background-color: #0872b1;
        color: #ffffff;
        text-align: left;
        top: 0;
        position: sticky;
    }
    .styled-table th,
    .styled-table td {
        padding: 12px 15px;
    }
    .styled-table td:nth-child(3),
    .styled-table th:nth-child(3)
    {
        text-align: center;
    }
    .styled-table tbody tr {
        border-bottom: 1px solid #dddddd;
    }

    .styled-table tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    .styled-table tbody tr:last-of-type {
        border-bottom: 2px solid #0872b1;
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            width: 80%;
            margin: auto;
            margin-top: 8%;
            margin-bottom: 6%;
            text-align: center;
            border: 5px solid #fdce08;
        }
        .text_titre h1{
            font-size: 175%;
        }
        h3{
            width: 80%;
            margin: auto;
            margin-bottom: 5%;
        }
        .flyer{
            width: 100%;
            margin-bottom: 4%;
        }
        .form{
            width: 96%;
            font-size: 90%;
        }
        .date{
            margin-bottom: 2%;
        }
        .listeInscrits{
            flex-direction: column;
            margin-bottom: 4%;
        }
    }
</style>
